<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ invitation.dealer_name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              $currentUserCan($permissions.PERM_DELETE_INVITATIONS) &&
              invitation.status !== 'approved'
            "
            @click="deleteInvitation"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              $currentUserCan($permissions.PERM_EDIT_INVITATIONS) &&
              invitation.status !== 'approved'
            "
            @click="editInvitation"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="invitation.status !== 'approved'"
            @click="approveInvitation"
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.APPROVE") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ invitation.first_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            <div v-html="invitation.last_name"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ invitation.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{ invitation.mobile }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ invitation.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(invitation.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(invitation.updated_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <invitation-status-badge-component :invitation="invitation" />
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.BILLING_ADDRESS") }}
        </p>
      </div>

      <billing-informations-view :billingInformations="invitation" />
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import InvitationStatusBadgeComponent from "../components/InvitationStatusBadgeComponent";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "invitation-view-global",

  components: { InvitationStatusBadgeComponent, BillingInformationsView },

  props: ["invitation"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {
    editInvitation() {
      this.$emit("onEditInvitation", this.invitation);
    },
    deleteInvitation() {
      this.$emit("onDeleteInvitation", this.invitation);
    },
    approveInvitation() {
      this.$emit("onApproveInvitation", this.invitation);
    },
  },

  mounted() {},

  watch: {
    invitation(invitation) {},
  },
};
</script>
