<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-select
          clearable
          class="select-primary"
          min-width="240"
          v-model="status"
          :placeholder="$t('COMMON.STATUS')"
        >
          <el-option
            v-for="item in INVITATION_STATUS"
            class="select-primary text-uppercase"
            :label="
              $t(`INVITATIONS.STATUS_${item.toUpperCase()}`).toUpperCase()
            "
            :value="item"
            :key="item"
          >
          </el-option>
        </el-select>

        <base-input
          v-model="query"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>

      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="invitations"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              label="Dealer Name"
              prop="dealer_name"
              sortable="custom"
              min-width="220"
            />
            <el-table-column
              :label="$t('COMMON.STATUS')"
              prop="status"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <invitation-status-badge-component :invitation="row" />
              </template>
            </el-table-column>

            <el-table-column
              label="Email"
              sortable="custom"
              prop="email"
              min-width="220"
            >
            </el-table-column>

            <el-table-column label="Mobile" prop="mobile" min-width="220">
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="createdAt"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <span> {{ $formatDate(row.createdAt) }}</span>
              </template>
            </el-table-column>

            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_INVITATIONS)"
                >
                  <a
                    type="text"
                    @click="viewInvitation(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_INVITATIONS)"
                >
                  <a
                    type="text"
                    @click="editInvitation(row)"
                    class="table-action"
                    :class="{ disabled: row.status === 'approved' }"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_INVITATIONS)"
                >
                  <a
                    type="text"
                    @click="deleteInvitation(row)"
                    class="table-action table-action-delete"
                    :class="{ disabled: row.status === 'approved' }"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import { INVITATION_STATUS } from "@/constants/invitations";
import InvitationStatusBadgeComponent from "../components/InvitationStatusBadgeComponent";

export default {
  name: "Invitation-list-table",

  components: {
    BasePagination,
    InvitationStatusBadgeComponent,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {},

  data() {
    return {
      query: null,
      selectedRows: [],
      // sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      invitations: [],
      loading: true,
      INVITATION_STATUS,
      status: "waiting",
      // selectedReseller: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    status: {
      handler: "getListDebounced",
      immediate: true,
    },
    // selectedReseller: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          // include: "owner,reseller",
        };

        if (this.status) {
          params = {
            ...params,
            filter: { ...params.filter, status: this.status },
          };
        }

        await this.$store.dispatch("invitations/list", params);
        this.invitations = this.$store.getters["invitations/list"];
        this.total = this.$store.getters["invitations/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteInvitation(invitation) {
      this.$emit("onDeleteInvitation", invitation);
    },

    viewInvitation(invitation) {
      this.$emit("onViewInvitation", invitation);
    },

    editInvitation(invitation) {
      this.$emit("onEditInvitation", invitation);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
<style scoped>
a.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed !important;
}
</style>
