<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("INVITATIONS.INVITATIONS_LIST") }}</h3>
          </div>
          <base-button
            class="elite-button add ml-auto"
            icon
            size="sm"
            @click="openInvitationCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_INVITATIONS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus-circle"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("INVITATIONS.ADD_INVITATION") }}
            </span>
          </base-button>
        </div>
      </div>

      <Invitation-list-table
        @onViewInvitation="openInvitationViewModal"
        @onEditInvitation="openInvitationEditModal"
        @onDeleteInvitation="deleteInvitation"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewInvitationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewInvitationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'Invitation_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INVITATIONS.VIEW_INVITATION") }}
              </h1>
            </div>

            <div></div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openInvitation"
                :objectType="'Invitations'"
                :objectId="openInvitation.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeInvitationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-Invitation-component
              v-if="openInvitation"
              :invitationId="openInvitation.id"
              @onEditInvitation="openInvitationEditModal"
              @onDeleteInvitation="deleteInvitation"
              @onApproveInvitation="approveInvitation"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditInvitationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditInvitationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'Invitation'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INVITATIONS.EDIT_INVITATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openInvitationViewModal(openInvitation)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeInvitationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-Invitation-component
              v-if="openInvitation"
              :invitationId="openInvitation.id"
              @onViewInvitation="openInvitationViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddInvitationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddInvitationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'Invitation'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INVITATIONS.ADD_INVITATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeInvitationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <add-Invitation-component
              @onViewInvitation="openInvitationViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import InvitationListTable from "./partials/InvitationListTable.vue";
import EditInvitationComponent from "./components/EditInvitationComponent.vue";
import AddInvitationComponent from "./components/AddInvitationComponent.vue";
import ViewInvitationComponent from "./components/ViewInvitationComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    InvitationListTable,
    NotificationSubscription,
    EditInvitationComponent,
    AddInvitationComponent,
    ViewInvitationComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const invitationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewInvitationModalOpened = false;
    let isEditInvitationModalOpened = false;
    let isAddInvitationModalOpened = false;
    let openInvitation = null;
    if (invitationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewInvitationModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditInvitationModalOpened = true;
      }
      openInvitation = { id: invitationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddInvitationModalOpened = true;
    }
    return {
      isViewInvitationModalOpened: isViewInvitationModalOpened,
      isEditInvitationModalOpened: isEditInvitationModalOpened,
      isAddInvitationModalOpened: isAddInvitationModalOpened,
      openInvitation: openInvitation,
      renderKey: 1,
    };
  },

  methods: {
    openInvitationCreateModal() {
      this.closeInvitationModal();
      this.isAddInvitationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Invitations",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openInvitationViewModal(invitation, reRender = false) {
      this.closeInvitationModal();
      this.openInvitation = invitation;
      this.isViewInvitationModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Invitations",
          query: { id: this.openInvitation.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openInvitationEditModal(invitation) {
      this.closeInvitationModal();
      this.openInvitation = invitation;
      this.isEditInvitationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Invitations",
          query: { id: this.openInvitation.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeInvitationModal() {
      this.isAddInvitationModalOpened = false;
      this.isViewInvitationModalOpened = false;
      this.isEditInvitationModalOpened = false;
      this.openInvitation = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Invitations",
          query: {},
        }).href
      );
    },

    async deleteInvitation(Invitation) {
      const confirmation = await swal.fire({
        text: this.$t("INVITATIONS.DELETE_THIS_INVITATION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn base-button btn-primary",
        cancelButtonClass: "btn base-button btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("invitations/destroy", Invitation.id);
          this.renderKey++;
          this.closeInvitationModal();
          this.$notify({
            type: "success",
            message: this.$t("INVITATIONS.Invitation_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async approveInvitation(Invitation) {
      const confirmation = await swal.fire({
        text: this.$t("INVITATIONS.APPROVE_THIS_INVITATION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-success",
        cancelButtonClass: "btn base-button",
        // cancelButtonClass: "btn base-button btn-outline-light",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("invitations/approve", Invitation.id);
          this.renderKey++;
          this.closeInvitationModal();
          this.$notify({
            type: "success",
            message: this.$t("INVITATIONS.INVITATION_APPROVED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
<style lang="css">
button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
